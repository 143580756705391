<template>
  <div>
    <el-row>
      <el-button type="success" @click="bindPrint" fit="scale-down"
        >打印</el-button
      >
    </el-row>

    <el-row>
      <el-col :span="20" :push="2">
        <!-- <div id="printTable"> -->
        <div class="img">
          <div id="printTable">
            <div class="name">{{ printDetail.name }}</div>
            <div class="year">{{ printDetail.grade }}</div>

            <div class="information">
              <div>{{ printDetail.college_name }}</div>
              <div class="specialized">{{ printDetail.domain_name }}</div>
              <div>{{ printDetail.level_name }}</div>
            </div>

            <div class="time_1">
              <div class="moon">{{ printDetail.start_m }}</div>
              <div class="day">{{ printDetail.start_d }}</div>
            </div>

            <div class="time_2">
              <div class="moon">{{ printDetail.end_m }}</div>
              <div class="day">{{ printDetail.end_d }}</div>
            </div>

            <div class="date">
              <div class="year_3">{{ printDetail.y }}</div>
              <div class="moon_3">{{ printDetail.m }}</div>
              <div class="day_3">{{ printDetail.d }}</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import print from 'print-js'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { printAPI } from '@/api/article'

const router = useRoute()
console.log(router.params.img)

const printDetail = ref({})
const onPrint = async () => {
  const data = await printAPI({ id: router.params.img })
  printDetail.value = data
}
onPrint()

const bindPrint = () => {
  print({
    printable: 'printTable',
    type: 'html',
    header: null,
    targetStyles: ['*'],
    style: '@page {margin: 10px}'
  })
}

// const onSuccess = () => {
//   console.log('@@')
// }
</script>

<style lang="scss" scoped>
.img {
  background-image: url('../../assets/img/tong_zhi_shu.jpeg');
  background-size: 100%;
  width: 1100px;
  height: 800px;
  // margin-top: 27px;
  margin-left: 34px;
  margin-top: -9px;
}

.name {
  position: absolute;
  top: 243px;
  left: 120px;
  font-weight: 900;
}

.year {
  position: absolute;
  top: 314px;
  left: 145px;
  font-weight: 900;
}

.information {
  position: absolute;
  top: 350px;
  left: 224px;
  font-weight: 900;
  .specialized {
    margin: 12px 0;
  }
}

.time_1 {
  position: absolute;
  top: 443px;
  left: 363px;
  display: flex;
  font-weight: 900;
  .day {
    margin-left: 30px;
  }
}

.time_2 {
  position: absolute;
  top: 480px;
  left: 130px;
  display: flex;
  font-weight: 900;
  .day {
    margin-left: 25px;
  }
}

.date {
  position: absolute;
  top: 626px;
  left: 340px;
  display: flex;
  font-weight: 900;

  .moon_3 {
    margin: 0 30px;
  }
}
</style>
